import React from "react";
import style from "./banner4.module.css";
import banner4 from "../../assets/banner4.png";
import p1 from "../../assets/p1.png";
import p2 from "../../assets/p2.png";
import p3 from "../../assets/p3.png";
import p4 from "../../assets/p4.png";


const Banner4 = () => {
  return (
    <div className={style.main}>
      <div className={style.card}>
        <div className={style.left}>
          <img src={banner4} alt="img" className={style.img1} />
        </div>
        <div className={style.right}>
          <p className={style.haiding}>Our Partners</p>
          <p className={style.detail}>
            Collaborating with industry leaders, technology innovators, and
            forward-thinking organizations, we strive to create synergistic
            relationships that unlock new opportunities and push the boundaries
            of what's possible. Through our partnerships, we leverage collective
            expertise, resources, and networks to deliver cutting-edge solutions
            and address complex challenges. Together, we're shaping the future
            of technology and empowering individuals and businesses worldwide to
            thrive in the digital economy.
          </p>
          </div>
      
      </div>
    </div>
  );
};

export default Banner4;
