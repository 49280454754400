import React from "react";
import style from "./banner2.module.css";
import banner2 from "../../assets/banner2.png"

const Banner2 = () => {
  return (
    <div className={style.main}>
      <div className={style.card}>
      <div className={style.left} >
      <img src={banner2} alt="img" className={style.img1} />

      </div>
        <div className={style.right} >
        <p className={style.haiding} >Our Solutions</p>
        <p className={style.detail} >
        We focus on scalable social and financial networks to bridge social economy and businesses to build thriving online communities, engage with their audience, and forward meaningful connections. With features designed to enhance user experience and drive engagement, our social platforms provide the foundation for building vibrant online ecosystems.

        </p>
        <p className={style.detail} style={{marginTop:'25px'}}>
        Our payment service solutions leverage blockchain technology to provide secure, efficient, and cost-effective payment processing. Whether you're an individual, a small business looking to accept cryptocurrency payments or a large enterprise in need of cross-border payment solutions, directoapp offers payment services to meet your needs.

        </p>

        </div>
      </div>
      <div className={style.coloredline}></div>

    </div>
  );
};

export default Banner2;