import Banner1 from "../components/landing/Banner1";
import Banner2 from "../components/landing/Banner2";
import style from "../pages/landing.module.css";
import { Text } from "react";
import Banner3 from "../components/landing/Banner3";
import Banner4 from "../components/landing/Banner4";
import Partners from "../components/landing/Partners";

const LandingPage = () => {
  return (
    <>
      <div className={style.card}>
        <div className={style.cont}>
        <h1 className={style.heading}>The Future Is Now </h1>
        <h6 className={style.sub}>
          Evolving Global
          Interaction and Commerce using Blockchain Technology
        </h6>
        </div>
      </div>
      <Banner1 />
      <Banner2 />
      <Banner3 />
      <Banner4 />
      <Partners />
    </>
  );
};

export default LandingPage;
