import React from "react";
import style from "./banner1.module.css";
import banner1 from "../../assets/banner1.png";

const Banner1 = () => {
  return (
    <div className={style.main}>
      <div className={style.card}>
        <div className={style.left}>
          <p className={style.haiding}>Welcome</p>
          <p className={style.detail}>
            directoapp, where innovation meets blockchain excellence. We pride
            ourselves on crafting advanced solutions within the blockchain
            ecosystems that redefine industry standards and empower businesses
            to thrive in the digital age. We are a team of forward-thinking
            experts. Whether you're seeking to streamline operations, enhance
            security, or unlock new opportunities, our dedication to innovation
            ensures the best solutions. Join us on the journey to revolutionize
            the future of technology with directoapp multi-ecosystem
            technologies.
          </p>
        </div>
        <div className={style.right}>
          <img src={banner1} alt="img" className={style.img1} />
        </div>
      </div>
      <div className={style.coloredline}></div>
    </div>
  );
};

export default Banner1;
