import React, { useEffect } from "react";
import { Routes, Route, useNavigate, Router } from "react-router-dom";
import Layout from "./components/layout/Layout";
import LandingPage from "./pages/Landing";
import DXS from "./pages/Dxs";

const App = () => {

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<LandingPage />} />
        {/* <Route path="/dxs" element={<DXS />} /> */}
      </Route>
    </Routes>
  );
};

export default App;
