import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import style from "./header.module.css";

const Header = () => {
  const history = useNavigate();
  const currentPath = window.location.pathname;
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(`${currentPath}`); // State to track active link

  const menuOpener = () => {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeHandler = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
    closeHandler();
  };

  return (
    <div className={style.main}>
      <div className={style.header}>
        <div className={style.logoC} onClick={() => history("/")}>
          <span className={style.title}>directoapp</span>
        </div>
        {!isOpen && <FiMenu onClick={menuOpener} className={style.menuIcon} />}
        {isOpen && (
          <IoClose onClick={closeHandler} className={style.menuIcon} />
        )}
        <div className={style.items}>
          <Link
            to="/"
            className={activeLink === "/" ? style.active : ""}
            onClick={() => handleLinkClick("/")}
          >
            Home
          </Link>
          <Link
            to="https://nuconsortium.xyz/"
            className={activeLink === "nu" ? style.active : ""}
            onClick={() => handleLinkClick("nu")}
          >
            nu
          </Link>
          {/* <Link
            to="/dxs"
            className={activeLink === "/dxs" ? style.active : ""}
            onClick={() => handleLinkClick("/dxs")}
          >
            DXS
          </Link> */}
          <Link
            to="https://t.me/directoapp"
            className={activeLink === "contact" ? style.active : ""}
            onClick={() => handleLinkClick("contact")}
          >
            Contact Us
          </Link>
        </div>
      </div>
      <div className={isOpen ? style.open : style.mobileM}>
        <div className={style.links}>
          <Link
            to="/"
            className={activeLink === "/" ? style.active : ""}
            onClick={() => handleLinkClick("/")}
          >
            Home{" "}
          </Link>
          <Link
            to="https://nuconsortium.xyz/"
            className={activeLink === "nu" ? style.active : ""}
            onClick={() => handleLinkClick("nu")}
          >
            nu
          </Link>
          {/* <Link
            to="/dxs"
            className={activeLink === "/dxs" ? style.active : ""}
            onClick={() => handleLinkClick("/dxs")}
          >
            DXS{" "}
          </Link> */}
          <Link
            to="https://t.me/directoapp"
            className={activeLink === "contact" ? style.active : ""}
            onClick={() => handleLinkClick("contact")}
          >
            Contact Us
          </Link>
        </div>
      </div>
      <div
        className={style.coloredline}
        style={{
          width: "65%",
          height: "1px",
          backgroundColor: "#00FB30",
          margin: "0 auto",
        }}
      ></div>
    </div>
  );
};

export default Header;
