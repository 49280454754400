import React from "react";
import style from "./footer.module.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className={style.main}>
      {/* <div className={style.card}>
        <div className={style.left}></div>
        <div className={style.right}>
          <div className={style.lower}>
            <div className={style.itemCont}>
              <Link className={style.linkItems}>Investor Relations</Link>
              <Link className={style.linkItems} to="/whitepaper">
                Want To Partnership?
              </Link>
              <Link
                className={style.linkItems}
                to="https://t.me/contact_nuconsortium"
              >
                Media
              </Link>
              <Link className={style.linkItems}>Contact Us</Link>
            </div>
          </div>
        </div>
      </div> */}
      <div className={style.linktree}>
        <p className={style.directoapp}>Copy Rights @ directoapp Inc.  2024</p>
      </div>
    </div>
  );
};

export default Footer;
