import p1 from "../../assets/p1.png";
import p2 from "../../assets/p2.png";
import p3 from "../../assets/p3.png";
import p4 from "../../assets/p4.png";
import style from "./partners.module.css";


const Partners = () =>{
    return(
        <><div className={style.pCard}>
            <div className={style.left}>
            </div>
            <div className={style.right}>
                <div className={style.partnerCont}>
                    <img src={p1} alt="img" className={style.pImg1} />
                    <img src={p2} alt="img" className={style.pImg2} />
                    <img src={p3} alt="img" className={style.pImg3} />
                </div>
            </div>

        </div><div className={style.coloredline} style={{ width: '65%', height: '1px', backgroundColor: '#00FB30', margin: '0 auto' }}></div></>

    )
}

export default Partners;