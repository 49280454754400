import React from "react";
import style from "./banner1.module.css";
import banner3 from "../../assets/banner3.png";

const Banner3 = () => {
  return (
    <div className={style.main}>
      <div className={style.card}>
        <div className={style.left}>
          <p className={style.haiding}>Our Mission</p>
          <p className={style.detail}>
            Our mission is to provide seamless services to enable global
            transactions with fewer fees and faster processing times,
            revolutionizing the way businesses conduct financial transactions.
            With directoapp, we break down barriers to financial access,
            empowering the billions of unbanked individuals worldwide to
            effortlessly access funds and participate in the digital economy. We
            are committed to delivering solutions that empower businesses to
            harness the full potential of blockchain technology.
          </p>
        </div>
        <div className={style.right}>
          <img src={banner3} alt="img" className={style.img1} />
        </div>
      </div>
      <div className={style.coloredline}></div>
    </div>
  );
};

export default Banner3;
